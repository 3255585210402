import React from 'react';
import HeroSection from './herosection';
import HeaderNew from './header_new';
// import Header from './header'

const HeaderHero = () => {
    return (
        <div>
            {/* <Header></Header> */}
            <HeaderNew></HeaderNew>
            <HeroSection></HeroSection>
        </div>
    );
}

export default HeaderHero;
