// src/components/Partners.js
import React, { useEffect, useRef } from 'react';
import './partners.css';
import Logo1 from '../../images/p20.jpg';
import Logo2 from '../../images/p21.jpg';
import Logo8 from '../../images/p1.jpg';
import Logo9 from '../../images/p2.jpg';
import Logo10 from '../../images/p3.jpg';
import Logo11 from '../../images/p4.jpg';
import Logo12 from '../../images/p5.jpg';
import Logo13 from '../../images/p6.jpg';
import Logo14 from '../../images/p7.jpg';
import Logo15 from '../../images/p8.jpg';
import Logo16 from '../../images/p9.jpg';
import Logo17 from '../../images/p10.jpg';
import Logo18 from '../../images/p11.jpg';
import Logo19 from '../../images/p12.png';
import Logo20 from '../../images/p13.jpg';
import Logo21 from '../../images/p14.jpg';
import Logo22 from '../../images/p15.jpg';
import Logo23 from '../../images/p16.jpg';
import Logo24 from '../../images/p17.jpg';
import Logo25 from '../../images/p18.jpg';
import Logo26 from '../../images/p19.jpg';

const logos = [
  Logo1, Logo2, Logo8, Logo9, Logo10,
  Logo11, Logo12, Logo13, Logo14, Logo15, Logo16, Logo17, Logo18, Logo19,
  Logo20, Logo21, Logo22, Logo23, Logo24, Logo25, Logo26
];

const Partners = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const totalWidth = containerRef.current.scrollWidth;
      const viewportWidth = containerRef.current.clientWidth;
      containerRef.current.style.setProperty('--total-width', `${totalWidth}px`);
      containerRef.current.style.setProperty('--viewport-width', `${viewportWidth}px`);
    }
  }, []);

  return (
    <div className="logo-slider">
      <div className="logo-container" ref={containerRef}>
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`Logo ${index + 1}`} className="logo" />
        ))}
        {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo duplicate ${index + 1}`} className="logo" />
        ))}
      </div>
    </div>
  );
};

export default Partners;
