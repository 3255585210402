import React from "react";
import Footer from "../footer/footer";
import PopularDestinations from "../populardestinations/populardestinations";
import HeaderHero from "../header/header-hero";
import AboutUs from "../about/about";
import Services from "../services/services";
import Reasons from "../reasons/reasons";
import Standards from "../standard/standard";
import Philosophy from "../about/philosophy";
import Contact from "../contact/contact";
import Bookingcomp from "../booking/bookingcomp";
import Feedback from "../populardestinations/feedback";

const Homepage = () => {
  return (
    <div>
      <HeaderHero></HeaderHero>
      <Bookingcomp></Bookingcomp>
      <AboutUs></AboutUs>
      <Philosophy></Philosophy>
      <Services></Services>
      <Reasons></Reasons>
      {/* <Statistics></Statistics> */}
      <Standards></Standards>
      <PopularDestinations></PopularDestinations>
      {/* <Membership></Membership> */}
      {/* <Blog></Blog> */}
      <Contact></Contact>
      <Feedback></Feedback>
      <Footer></Footer>
    </div>
  );
};

export default Homepage;
