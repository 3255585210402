import {Route, Routes} from 'react-router'
import './App.css';
import Homepage from './pages/homepage/homepage';

function App() {
  return (
  <Routes>
    <Route path='/' element={<Homepage></Homepage>}></Route>
    <Route path='/aboutus' element={<Homepage></Homepage>}></Route>
    <Route path='*' element={<Homepage></Homepage>}></Route>
  </Routes>
  );
}

export default App;
