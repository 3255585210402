// src/components/PopularDestinations.js

import React, { useState, useEffect, useCallback } from "react";
import "./populardestinations.css";
import Image from "../../images/venice.jpeg";
import Image1 from "../../images/greatwall.jpeg";
import Image2 from "../../images/statute of liberty.jpeg";
import Image3 from "../../images/taj mahal.jpeg";
import Image4 from "../../images/opera house.jpeg";
import Image5 from "../../images/colloseum.jpeg";
import Image6 from "../../images/grandcanyon.jpeg";
import Image7 from "../../images/eiffel.jpeg";
import Image8 from "../../images/sydney.jpeg";
import Image9 from "../../images/machu.jpeg";
import Image10 from "../../images/pyramid.jpeg";
import Image11 from "../../images/santorini.jpeg";
import Image12 from "../../images/petra.jpeg";
import Image13 from "../../images/halong.jpeg";
import Image14 from "../../images/angkor.jpeg";
import Image15 from "../../images/zuma.jpeg";
import Image16 from "../../images/obudu.jpeg";
import Image17 from "../../images/ikogosi.jpeg";
import Image18 from "../../images/olumo.jpeg";
import Image19 from "../../images/angkor.jpeg";

const destinations = [
  { name: "Great Wall", tours: 356, imageUrl: Image1 },
  { name: "Zuma Rock", tours: 200, imageUrl: Image15 },
  { name: "Statue of Liberty", tours: 356, imageUrl: Image2 },
  { name: "Obudu Mountain Resort", tours: 100, imageUrl: Image16 },
  { name: "Taj Mahal", tours: 356, imageUrl: Image3 },
  { name: "Opera House", tours: 356, imageUrl: Image4 },
  { name: "Ikogosi Warm Springs", tours: 120, imageUrl: Image17 },
  { name: "Colosseum", tours: 356, imageUrl: Image5 },
  { name: "Grand Canyon", tours: 356, imageUrl: Image6 },
  { name: "Olumo Rock", tours: 150, imageUrl: Image18 },
  { name: "Eiffel Tower", tours: 356, imageUrl: Image7 },
  { name: "Venice", tours: 356, imageUrl: Image },
  { name: "Yankari Game Reserve", tours: 250, imageUrl: Image19 },
  { name: "Sydney Opera House", tours: 356, imageUrl: Image8 },
  { name: "Machu Picchu", tours: 356, imageUrl: Image9 },
  { name: "Pyramids of Giza", tours: 356, imageUrl: Image10 },
  { name: "Santorini", tours: 356, imageUrl: Image11 },
  { name: "Petra", tours: 356, imageUrl: Image12 },
  { name: "Ha Long Bay", tours: 356, imageUrl: Image13 },
  { name: "Angkor Wat", tours: 356, imageUrl: Image14 },
];

const PopularDestinations = () => {
  const [current, setCurrent] = useState(0);
  const length = destinations.length;

  const nextSlide = useCallback(() => {
    setCurrent(current === length - 7 ? 0 : current + 1);
  }, [current, length]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
  }, [nextSlide]);
  return (
    <div className="popular-destinations">
      <div className="about-header-container">
        <h2>Popular Destinations</h2>
        <div className="underscore"></div>
      </div>
      <br></br>
      <p id="subheaderr">Navigate the Globe with Confidence</p>
      <div className="carousel-popular">
        {destinations.map((destinations, index) => (
          <div key={index} className="carousel-item">
            <img src={destinations.imageUrl} alt={destinations.name} />
            <h3>{destinations.name}</h3>
            <p>{destinations.tours} Tours</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularDestinations;
