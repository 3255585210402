import React from "react";
import "./about.css";
import AboutSVG from "../../images/aboutus1.jpg";
import Partners from "../partners/partners";

const About = () => {
  return (
    <div className="App" id="about">
      <div className="about-container">
        <div className="about-header-container">
          <h2>About Us</h2>
          <div className="underscore"></div>
        </div>

        <div className="about-hero-section">
          <div className="img-container">
            <img
              className="about-img"
              src={AboutSVG}
              alt='about_nodamec'
            />
          </div>
          <div className="about-text-content">
            <p>
              Nodamec Travels Limited meets the global standard for Travel
              Management Companies thereby being certified by International Air
              Transport Association (IATA),as well as duly certified
              by the Nigeria Civil Aviation Authority (NCAA) and an active member of National Association of Nigeria Travel Agencies (NANTA).
            </p>
            <div className="about-members" style={{display: 'flex',justifyContent:'center',}}>
              <img src={require("../../images/p25.jpg")} alt="Member 4" />
              <img src={require("../../images/p24.jpg")} alt="Member 3" />
              <img src={require("../../images/p23.jpg")} alt="Member 2" />
            </div>

            <div className="services-offered">
              <p>
                NODAMEC TRAVELS LIMITED runs a user-friendly automated
                reservation system with the Global Distribution Systems (GDS)
                like Amadeus, Galileo and Seba. These enhancements of
                flexibility and efficiency in the sales of airline’s products
                and services with experienced and well-trained personnel in the
                airline business have efficiently helped us to provide
                world-class travel services to both individuals and corporate
                bodies thereby placing us in a better position to serve you
                well.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="partners">
        <div className="about-header-container">
          <h2>Meet our Partners</h2>
          <div className="underscore"></div>
        </div>
        <br></br>
        <br></br>
        <p className="additional-info">
          Our partnerships allow us to offer exclusive deals, comprehensive
          support, and unmatched value in the travel industry.
        </p>
        <div className="services-offered">
          <Partners />
        </div>
      </div>
    </div>
  );
};

export default About;
