import React, { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import './header.css'

const HeaderNew = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  return (
    <div className="menu-header">
      <div className="logo">
        <img src={require("../../images/Nodamec Logo 2.png")} alt="Nomadec." />
      </div>
        <nav className={isMenuOpen ? 'open' : ''}>
          <ul>
            <li><a className="single-menu" href="/">Home</a></li>
            <li><a className="single-menu" href="#about">About</a></li>
            <li><a className="single-menu" href="#services">Services</a></li>
            <li><a className="single-menu" href="#blog">Blog</a></li>
            <li><a className="single-menu" href="#contact">Contact</a></li>
          </ul>
        </nav>
      <div className="enquiries" >
        <BsWhatsapp style={{fontSize:'20px'}}/>
        <a className="p-link" href="https://wa.me/+2348030451579?text=Hello,I will like to enquire about a trip" title="We respond in less than 10mins">Make Enquiry</a>
      </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
    </div>
  );
};

export default HeaderNew;
