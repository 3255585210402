import React from 'react';
import StandardCard from './standardCard';
import './standard.css';


const standards = [
  { title: '100% Satisfaction Guaranteed', description:'One of our greatest desire is to satisfy all our clients, a lot of our clients has returned so many thanks and recommendation over a job well done',index:"happy",count:1 },
  { title: 'Valid Hotel Booking For Visa Application',description:'We work with reliable hotels over the world hence all our hotels reservation are valid',index:"hotel",count:2},
  { title: 'Email Or Phone Confirmation Directly From Hotel', description:' Once we reserve a hotel for you, the hotel confirmed your email and phone number directly and instantly.' ,index:"phone",count:3},
  { title: 'Top Rated Hotels', description:'We deals with top and highly ranked hotels with affordable cost.',index:"top",count:4 }
];

const Standards = () => {
  return (
    <div className="popular-standards">
        <div className="about-header-container">
          <h2>Nodamec Way</h2>
          <div className="underscore"></div>
        </div>
        <br></br>
      <p id='subheader'>Over the years we have offered affordable, flexible and the best quality of flight ticketing and hotel reservation in a highly dedicated and professionally ways.</p>
      <div className="standard-cards">
        {standards.map((standard, index) => (
          <StandardCard
            key={index}
            title={standard.title}
            description={standard.description}
            index={standard.index}
            count={standard.count}
          />
        ))}
      </div>
    </div>
  );
};

export default Standards;
