import axios from "axios";
const url =
  "https://script.google.com/macros/s/AKfycbzLuYjr1sZ5L9iEClsprNo6_SwFbO11ncqUb2EtlxudZEUFAyIBVxsLxJpv4aqQZ5xY/exec";

export async function NewVisitor() {
  const visited = localStorage.visited;
  if (visited !== "visited") {
    await axios.get(`${url}?path=nodamecconnect`);
    localStorage.visited = "visited";
  }
}
export async function NewsLetter(email) {
  try {
    await axios.get(
      `https://script.google.com/macros/s/AKfycbzLuYjr1sZ5L9iEClsprNo6_SwFbO11ncqUb2EtlxudZEUFAyIBVxsLxJpv4aqQZ5xY/exec?path=nodamecconnectletter&email=${email}`
    );
  } catch (error) {
    console.log(error);
  }
}
export async function NewBookingRegistration(data) {
  await axios.post(`${url}?path=nodamecconnect`, JSON.stringify(data));
}
export async function NewContactUsRegistration(data) {
  try {
    await axios.post(`${url}?path=nodameccontact`, JSON.stringify(data));
  } catch (error) {
    console.log(error);
  }
  console.log(data);
}
export async function FlightDetailsRegistration(data) {
  await axios.post(`${url}?path=nodamecmanage`, JSON.stringify(data));
}
