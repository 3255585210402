import React from "react";
import "./standard.css";
import { MdContactPhone } from "react-icons/md";
import { FaHotel } from "react-icons/fa6";
import { ImHappy2 } from "react-icons/im";
import { GrValidate } from "react-icons/gr";

const StandardCard = ({ title, description,index,count }) => {
  const getColor=(count)=>{
    switch(count){
      case 1:
        return "#034694"
      case 2:
        return "#002D62"
      case 4:
        return "#13274F"
      default:
        return "#0066b2"
    }
  }
  const getIcon = (index,count) => {
    switch (index) {
      case "hotel":
        return <GrValidate style={{ color: getColor(2)}}/>;
      case "phone":
        return <MdContactPhone style={{ color: getColor(3) }}/>;
      case "top":
        return <FaHotel style={{ color: getColor(4) }}/>;
      case "happy":
        return <ImHappy2 style={{ color: getColor(1) }}/>;
      default:
        return <ImHappy2 style={{ color: getColor(1) }} />;
    }
  };
  return (
    <div className="standard-container standard-card-size">
      <div className="standard-count">{getIcon(index,count+1)}</div>
      <div className="standard-card" style={{backgroundColor:getColor(count)}}>
        <div>
          <h3 style={{ fontSize: "18px" }}>{title}</h3>
          <p style={{ fontSize: "14px" }}>{description}</p>
        </div>
      </div>
      <div>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          className="standard-card-size mobile-inactive"
          fill={getColor(count)}
        >
          <path d="M0 0 L50 50 L0 100 Z" />
        </svg>
      </div>
    </div>
  );
};

export default StandardCard;
