import React, { useRef, useState } from "react";
import { GiCommercialAirplane } from "react-icons/gi";
import { GrContactInfo, GrUserSettings } from "react-icons/gr";
import { MdDateRange } from "react-icons/md";
import { FaRightLeft } from "react-icons/fa6";
import { BsFillPersonVcardFill, BsPersonFill } from "react-icons/bs";
import "./booking.css";
import {
  FlightDetailsRegistration,
  NewBookingRegistration,
} from "../../server/server";

const Bookingcomp = () => {
  const ref_depart = useRef();
  const ref_arrive = useRef();
  const [tab, tabChanged] = useState("flight");
  const [oneWay, oneWayChanged] = useState(false);
  const [surname, surnameChanged] = useState("");
  const [firstname, firstnameChanged] = useState("");
  const [fullname, fullnameChanged] = useState("");
  const [email, emailChanged] = useState("");
  const [phone, phoneChanged] = useState("");
  const [from, fromChanged] = useState("");
  const [to, toChanged] = useState("");
  const [departDate, departDateChanged] = useState("");
  const [toDate, toDateChange] = useState("");
  const [otherInfo, otherInfoChanged] = useState("");
  const [cabin, cabinChanged] = useState("");
  const RegisterForm = async () => {
    await NewBookingRegistration({
      oneWay: oneWay,
      fullname: `${surname} ${firstname}`,
      email: email,
      phone: phone,
      from: from,
      to: to,
      departDate: departDate,
      toDate: toDate,
      otherInfo: otherInfo,
      cabin: cabin,
    });
    oneWayChanged(false);
    fullnameChanged("");
    emailChanged("");
    phoneChanged("");
    fromChanged("");
    toChanged("");
    departDateChanged("");
    toDateChange("");
    otherInfoChanged("");
    cabinChanged("");
    alert(
      "We have noted your interest, a Kayrex personnel willo contact you shortly"
    );
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  };
  const submitRequest = async () => {
    await FlightDetailsRegistration({
      fullname: fullname,
      email: email,
      code: phone,
    });
    fullnameChanged("");
    emailChanged("");
    phoneChanged("");
  };
  return (
    <div className="booking-header-main">
      <div>
        <div className="header-container">
          {/* Header */}
          <div className="header-main">
            <div className="header-flex">
              <div
                className={
                  tab === "flight"
                    ? "header-tab header-tab-active"
                    : "header-tab header-tab-inactive"
                }
                onClick={() => tabChanged("flight")}
              >
                <GiCommercialAirplane style={{ fontSize: "20px" }} />
                <span className="mobile-inactive">Flights</span>
              </div>
              <div
                className={
                  tab === "manage"
                    ? "header-tab header-tab-active"
                    : "header-tab header-tab-inactive"
                }
                onClick={() => tabChanged("manage")}
              >
                <GrUserSettings style={{ fontSize: "20px" }} />
                <span className="mobile-inactive">Manage Schedule</span>
              </div>
              {/* <div
        className={
          tab === "others"
            ? "header-tab header-tab-active"
            : "header-tab header-tab-inactive"
        }
        onClick={() => tabChanged("others")}
      >
        <TbListCheck style={{ fontSize: "20px" }} />
        <span className="mobile-inactive">Others</span>
      </div> */}
            </div>
            {tab === "flight" ? (
              <div className="header-container-main">
                <h3 className="header-title">
                  Let's have your travel request here.
                </h3>
                <p className="header-subtitle">
                  You never miss a flight with Nodamec
                </p>
                <div className="trip-type">
                  <input
                    id="one"
                    type="radio"
                    name="trip"
                    value={oneWay}
                    onChange={(e) => oneWayChanged(e.target.value)}
                  />{" "}
                  <label for="one">
                    One-way &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <input
                    id="two"
                    type="radio"
                    name="trip"
                    value={!oneWay}
                    onChange={(e) => oneWayChanged(!e.target.value)}
                  />{" "}
                  <label for="two">Round Trip</label>
                </div>
                <div className="header-container-main-2">
                  <div className="destination-date">
                    <p className="trip-detail-header">Personal Details</p>
                    <div className="simple-flex">
                      <input
                        placeholder="Surname"
                        value={firstname}
                        onChange={(e) => firstnameChanged(e.target.value)}
                      />
                      <div className="iconCircle">
                        <BsPersonFill />
                      </div>
                      <input
                        placeholder="Other names"
                        value={surname}
                        onChange={(e) => surnameChanged(e.target.value)}
                      />
                    </div>
                    <br></br>
                    <div className="simple-flex">
                      <input
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => phoneChanged(e.target.value)}
                      />
                      <div className="iconCircle">
                        <GrContactInfo />
                      </div>
                      <input
                        placeholder="Active Email Address"
                        value={email}
                        onChange={(e) => emailChanged(e.target.value)}
                      />
                    </div>
                    <p className="trip-detail-header">Destination</p>
                    <div className="simple-flex">
                      <input
                        placeholder="From"
                        value={from}
                        onChange={(e) => fromChanged(e.target.value)}
                      />
                      <div className="iconCircle">
                        <FaRightLeft />
                      </div>
                      <input
                        placeholder="To"
                        value={to}
                        onChange={(e) => toChanged(e.target.value)}
                      />
                    </div>
                    <p className="trip-detail-header">Date(s)</p>
                    <div className="simple-flex">
                      <input
                        placeholder="Departure Date"
                        ref={ref_depart}
                        onFocus={() => (ref_depart.current.type = "date")}
                        onBlur={() => (ref_depart.current.type = "date")}
                        value={departDate}
                        onChange={(e) => departDateChanged(e.target.value)}
                      />
                      <div className="iconCircle">
                        <MdDateRange />
                      </div>
                      <input
                        // readOnly={oneWay}
                        placeholder="Return Date"
                        ref={ref_arrive}
                        onFocus={() => (ref_arrive.current.type = "date")}
                        onBlur={() => (ref_arrive.current.type = "date")}
                        value={toDate}
                        onChange={(e) => toDateChange(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="trip-detail-header">Other Info</p>
                    <div className="simple-flex">
                      <input
                        placeholder="No. of Pax"
                        list="traveller"
                        value={otherInfo}
                        onChange={(e) => otherInfoChanged(e.target.value)}
                      />
                      <datalist id="traveller">
                        <option>1 Adult</option>
                        <option>2 Adult</option>
                        <option>3 Adult</option>
                        <option>4 Adult</option>
                        <option>5+ Adult</option>
                      </datalist>
                      <div className="iconCircle">
                        <BsFillPersonVcardFill />
                      </div>
                      <select
                        value={cabin}
                        onChange={(e) => cabinChanged(e.target.value)}
                      >
                        <option>--Select Cabin--</option>
                        <option>Economic Class</option>
                        <option>Business Class</option>
                        <option>First Class</option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="scheduleButton"
                    onClick={() => RegisterForm()}
                  >
                    Schedule Trip
                  </button>
                </div>
              </div>
            ) : (
              <div className="header-container-main">
                <h3 className="header-title">Request Travel Details</h3>
                <p className="header-subtitle">
                  You never miss a flight with Nodamec
                </p>
                <div className="header-container-main-2">
                  <div className="destination-date">
                    <p className="trip-detail-header">Enter Travel Details</p>
                    <div className="simple-flex-single">
                      <input
                        placeholder="Fullname"
                        style={{ width: "100%" }}
                        value={fullname}
                        onChange={(e) => fullnameChanged(e.target.value)}
                      />
                    </div>
                    <br></br>
                    <div className="simple-flex">
                      <input
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => emailChanged(e.target.value)}
                      />
                      <div className="iconCircle">
                        <FaRightLeft />
                      </div>
                      <input
                        placeholder="Travel Code"
                        value={phone}
                        onChange={(e) => phoneChanged(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    className="scheduleButton"
                    onClick={() => submitRequest()}
                  >
                    Submit Request
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookingcomp;
