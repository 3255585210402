import React, { useState } from "react";
import "./contact.css";
import { NewContactUsRegistration } from "../../server/server";

const Contact = () => {
  const [fullname, fullnameChanged] = useState("");
  const [email, emailChanged] = useState("");
  const [phone, phoneChanged] = useState("");
  const [service, serviceChanged] = useState("");
  const [note, noteChanged] = useState("");
  const contactUs=async ()=>{
    await NewContactUsRegistration({
      fullname: fullname,
      email: email,
      phone: phone,
      service: service,
      note: note,
    });
    fullnameChanged("");
    emailChanged("");
    phoneChanged("");
    serviceChanged("");
    noteChanged("");
    alert(
      "We have noted your request, a Nodamec personnel will contact you within the hour"
    );
  }
  return (
    <div className="contact" id="contact">
      <div className="map">
        <iframe
          title="Google Maps"
          width="100%"
          height="400"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127214.91932515144!2d6.9512824415148025!3d4.861260996626979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1069d2741b8d1be1%3A0x148d9a459d0f2d1f!2sNODAMEC%20TRAVELS%20LIMITED!5e0!3m2!1sen!2sng!4v1721576295499!5m2!1sen!2sng"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>

      <div className="contact-section">
        <div className="get-in-touch">
          <h2>Get in Touch </h2>
          <p>We typically reply within 10minutes!</p>
          <input
            type="text"
            name="name"
            placeholder="Your Name (required)"
                              value={fullname}
                              onChange={(e)=>fullnameChanged(e.target.value)}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email (required)"
                              value={email}
                              onChange={(e)=>emailChanged(e.target.value)}
            required
          />
          <input type="tel" name="subject" placeholder="Phone Number" 
                              value={phone}
                              onChange={(e)=>phoneChanged(e.target.value)}/>
          <select name="status" required 
                              value={service}
                              onChange={(e)=>serviceChanged(e.target.value)}>
            <option value="" id="service" disabled selected hidden>
              Services
            </option>
            <option value="flight">Feedback/Complaint</option>
            <option value="flight">Flight Ticketing</option>
            <option value="tour">Tour Booking</option>
            <option value="visa">Visa Assistance</option>
            <option value="hotel">Hotel Booking</option>
            <option value="airport">Airport Transfer</option>
          </select>
          <textarea
          style={{width:'100%'}}
            name="message"
            placeholder="Type your Message"
                              value={note}
                              onChange={(e)=>noteChanged(e.target.value)}
          />
          <br></br>
          <br></br>
          <button onClick={()=>contactUs()}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
