import React from 'react';
import './philosophy.css';
import { BiTime } from 'react-icons/bi';
import { FaMoneyCheck } from 'react-icons/fa6';
import { MdOutlineFlightTakeoff } from 'react-icons/md';
import { GrMultiple } from 'react-icons/gr';

const Philosophy = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <h2>Our Philosophy</h2>
        <p>
        Our service is based on the establishment of good customer relationship and to provide prompt travel services at the best competitive fares for all Local, Regional and International trips.
        </p>
        {/* <img id='arrow' src={require('../../images/arror.jpg')} alt="Pilot" /> */}
      </div>
      <div className="philosophy">
          <div id='time' className='philosophies'><FaMoneyCheck style={{fontSize:'30px'}}></FaMoneyCheck> Save Money</div>
          <div id='delivery' className='philosophies'><BiTime style={{fontSize:'30px'}}></BiTime>Prompt Service Delivery</div>
          <div id='plan' className='philosophies'><MdOutlineFlightTakeoff style={{fontSize:'30px'}} /> Plan your Trips</div>
          <div id='flight' className='philosophies'> <GrMultiple style={{fontSize:'30px'}} /> Multiple Flight Options</div>
        </div>
      </div>
  );
};

export default Philosophy;
