import React from 'react';
import './reasons.css';

const reasons = [
  {
    icon: 'fas fa-clock',
    title: 'Availability',
    description: 'Availability in areas of Response Time, Call Back Time, and Services Delivery.'
  },
  {
    icon: 'fas fa-tags',
    title: 'Affordable Rate',
    description: 'We offer the most competitive rates in the industry without compromising on service quality.'
  },
  {
    icon: 'fas fa-ticket-alt',
    title: 'Flexible Tickets',
    description: 'Our tickets come with flexible options, allowing you to make changes as per your convenience.'
  },
  {
    icon: 'fas fa-percentage',
    title: 'Discounted Fares',
    description: 'Enjoy significant discounts on fares, making your travel more affordable.'
  },
  {
    icon: 'fas fa-gift',
    title: 'Promotional Deals',
    description: 'Take advantage of our regular promotional deals to save more on your travels.'
  },
  {
    icon: 'fas fa-briefcase',
    title: 'Professionalism',
    description: 'Our team maintains the highest level of professionalism in all interactions.'
  },
  {
    icon: 'fas fa-undo-alt',
    title: 'Enhanced Refunds Procedures',
    description: 'Experience a hassle-free refund process with our enhanced procedures.'
  },
  {
    icon: 'fas fa-comments',
    title: 'Free Consultation and Advisory Services',
    description: 'Get free consultation and advisory services for all your travel needs.'
  }
];

const Reasons = () => {
  return (
    <section className="reasons" id="reasons">
      <div className="">
        <div className="about-header-container">
          <h2>Why Choose Nodamec?</h2>
          <div className="underscore"></div>
        </div>
        <br></br>
        <div className="reason-grid">
          {reasons.map((reason, index) => (
            <div className="reason-box" key={index}>
              <i className={reason.icon}></i>
              <h3>{reason.title}</h3>
              <p>{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reasons;
