import React, { useEffect, useState } from "react";
import "./herosection.css";
import cert from "../../images/cert.svg";
import { GiAirplaneDeparture } from "react-icons/gi";
import { MdAirplaneTicket } from "react-icons/md";
import { BiSolidDiscount } from "react-icons/bi";

const HeroSection = () => {
  const [backdrop, backdropChanged] = useState("passport-image");
  if(isNaN(localStorage.slideIndex))localStorage.slideIndex=0
  useEffect(() => {
    setInterval(() => {
      console.log(localStorage.slideIndex);
      const slides = [
        "passport-image",
        "slide-five",
        "slide-one",
        "slide-three",
        "slide-four",
      ];
      if (Number(localStorage.slideIndex) === slides.length - 1) {
        localStorage.slideIndex = 0;
      }
      backdropChanged(slides[localStorage.slideIndex]);
      localStorage.slideIndex = Number(localStorage.slideIndex) + 1;
    }, 5000);
  }, []);
  return (
    <section id="home">
      <div>
        <div className="top-layer">
          <div className="hero-content">
            <p className="subheading">
              <img style={{ width: "20px" }} src={cert} alt="check" />
              &nbsp; We are a certified travel agency with vast flight network..
            </p>
            <h1>
              Find the Perfect Flight to <br></br>your Destinations
            </h1>
            <div className="simple-flex">
              <GiAirplaneDeparture />
              <span>Wide Range of Airlines &nbsp;&nbsp;</span>
              <MdAirplaneTicket />
              <span>Flexible Ticket Options&nbsp;&nbsp;</span>

              <div className="simple-flex">
                <BiSolidDiscount />
                <span>Exclusive Discounts&nbsp;</span>
              </div>
            </div>
            {/* <div className="whatsapp-chat-container" onClick={handleClick}>
              <div className="whatsapp-icon">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </div>
              <div className="whatsapp-chat-label">Chat with an Agent</div>
            </div> */}
          </div>
        </div>
        <div className={`toplevel-bg hero ${backdrop}`}>
          <div className="hero-backdrop"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
