import React from "react";
import "./services.css";
import Hotel from "../../images/hotel.webp";
import Flight from "../../images/flight.jpg";
import Tour from "../../images/tour.jpeg";
import Visa from "../../images/visa.jpg";
import Airport from "../../images/airporttransfer.jpg";

const services = [
  { title: "Hotel Booking", img: Hotel },
  { title: "Flight Ticketing", img: Flight },
  { title: "Tour Booking", img: Tour },
  { title: "Visa Assistance", img: Visa },
  { title: "Airport Transfer", img: Airport },
];
const Services = () => {
  return (
    <section id="services" className="services">
      <div className="services-container">
        <div className="about-header-container">
          <h2>Our Services</h2>
          <div className="underscore"></div>
        </div>
        <br></br>
        <p className="additional-info" style={{textAlign:'center',lineHeight:'2'}}>
          <b>Nodamec Travels Limited</b> takes care of all your travel needs with exceptional customer service and prompt responses
        </p>
        <div style={{display:'flex', justifyContent:'center'}}>
          <div className="slide-wrapper">
            {services.map((service, index) => (
              <div key={index} className="slide">
                <div className="service-card">
                    <img src={service.img} alt={service.title} />
                    <h3>{service.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
