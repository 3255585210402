import React from "react";
import "./populardestinations.css";
import { BsPersonCircle } from "react-icons/bs";

const destinations = [
  {
    name: "Unwana Petters",
    tours:
      "Splendid services, clients are followed up to ensure they don't miss their flights",
  },
  {
    name: "Helen Jumbo",
    tours:
      "My experience was phenomenal with them,when my airline disappointed,they immediately notified me and got me the next available flight. Most importantly,your flight is covered and they remind you so you don't miss your flight. They are like mother hen,watching over her chick. Thanks so much,I am forever grateful to you guys,and will always recommend you,with my full chest💛💛💛",
  },
  { name: "Ozioma Iheanetu", tours: "Reliable. Dependable. Prompt." },
  {
    name: "Isaac Jatto",
    tours: `My experience with nodamec travels has been a wonderful one, the response time to my travel request is as fast as the speed of light , the follow up service, the day to day flight update to changes in flights schedule is just fantastic.

The cordial relationship with the staffs is endeared because of their warmth and friendliness, just share hospitality in display

Nodamec services delivery epitomises professionality

I recommend them to individual and corporate organisation who desire the best of travel experience`,
  },
  { name: "Janet Eneh", tours: "Efficient service at its peak." },
  {
    name: "Chinedu Chikezie",
    tours: "Nodamec is simply the best in the way they treat their clients .",
  },
];

const Feedback = () => {
  return (
    <div className="popular-destinations">
      <div className="about-header-container">
        <h2>Customer Reviews</h2>
        <div className="underscore"></div>
      </div>
      <br></br>
      <p id="subheaderr">Hear what our customer say about us</p>
      <br></br>
      <div className="carousel-popular">
        {destinations.map((destinations, index) => (
          <div key={index} className="carousel-item-feedback">
            <div style={{ marginLeft: "30px" }}>
              <BsPersonCircle
                style={{ fontSize: "80px", color: "#1c4753" }}
              ></BsPersonCircle>
            </div>
            <div className="review-contact">
              <h4 style={{ textAlign: "left", color: "#1c4753" }}>
                {destinations.name}
              </h4>
              <p style={{ textAlign: "left" }}>{destinations.tours}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Feedback;
